import React from "react";
import { Accordion, AccordionItem, PseudoBox, Text } from "@chakra-ui/core";

import { Link } from "gatsby";
const Sidebar = () => {
  return (
    // 总得sidebar
    <Accordion defaultIndex={[0]} allowMultiple>
      {/* 第一行 */}
      <AccordionItem border="0">
        <PseudoBox
          borderRadius="0.3rem"
          cursor="pointer"
          py="8px"
          px="16px"
          _hover={{
            color: "orange.300",
            paddingLeft: "25px",
            transition: " all 0.1s linear",
            bg: "rgb(233,233,233)",
          }}
        >
          <Link to="/ZK_Rollups" style={{ display: "block", width: "100%" }}>
            ZK Rollups
          </Link>
        </PseudoBox>
      </AccordionItem>

      {/* 第二行 */}
      <AccordionItem border="0">
        <PseudoBox
          borderRadius="0.3rem"
          cursor="pointer"
          py="8px"
          px="16px"
          _hover={{
            color: "orange.300",
            paddingLeft: "25px",
            transition: " all 0.1s linear",
            bg: "rgb(233,233,233)",
          }}
        >
          <Link
            to="/Optimistic_Rollups"
            style={{ display: "block", width: "100%" }}
          >
            Optimistic Rollups
          </Link>
        </PseudoBox>
      </AccordionItem>

      {/* 第三行 */}
      <AccordionItem border="0">
        <PseudoBox
          borderRadius="0.3rem"
          cursor="pointer"
          py="8px"
          px="16px"
          _hover={{
            color: "orange.300",
            paddingLeft: "25px",
            transition: " all 0.1s linear",
            bg: "rgb(233,233,233)",
          }}
        >
          <Link to="/Plasma" style={{ display: "block", width: "100%" }}>
            Plasma
          </Link>
        </PseudoBox>
      </AccordionItem>

      {/* 第四行 */}
      <AccordionItem border="0">
        <PseudoBox
          borderRadius="0.3rem"
          cursor="pointer"
          py="8px"
          px="16px"
          _hover={{
            color: "orange.300",
            paddingLeft: "25px",
            transition: " all 0.1s linear",
            bg: "rgb(233,233,233)",
          }}
        >
          <Link
            to="/zhuangtaitongdao"
            style={{ display: "block", width: "100%" }}
          >
            状态通道
          </Link>
        </PseudoBox>
      </AccordionItem>

      {/* 第五行 */}

      {/* 第四行 */}
      <AccordionItem border="0">
        <PseudoBox
          borderRadius="0.3rem"
          cursor="pointer"
          py="8px"
          px="16px"
          _hover={{
            color: "orange.300",
            paddingLeft: "25px",
            transition: " all 0.1s linear",
            bg: "rgb(233,233,233)",
          }}
        >
          <Link
            to="/Commit-Chains"
            style={{ display: "block", width: "100%" }}
          >
            Commit-Chains
          </Link>
        </PseudoBox>
      </AccordionItem>
    </Accordion>
  );
};

export default Sidebar;
